jQuery(function($) {
  // Disables keyboard handling in dropdown menus
  // To fix an issue with input fields in dropdowns
  // not allowing spaces to be entered
  //$('li.menu-text').off('keydown');
  $('.mobile-search .search-field').on('keydown', function(e) {
    e.stopPropagation();
  });

  $('#newsletterselector').on('change', function(e) {
    location.href = this.value;
  });

  $('.mobile-parent .has-children').on('click', function(e) {
    e.preventDefault();
    var url = this.getAttribute("data-href");
    if (url) {
      window.location.href = url;
    }
  });
});

function preloadImage(url) {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.href = url;
  link.as = 'image';
  link.setAttribute('fetchpriority', 'high');
  document.head.appendChild(link);
}