jQuery(function($) {
  // Disables keyboard handling in dropdown menus
  // To fix an issue with input fields in dropdowns
  // not allowing spaces to be entered
  //$('li.menu-text').off('keydown');
  $('.mobile-search .search-field').on('keydown', function(e) {
    e.stopPropagation();
  });

  $('#newsletterselector').on('change', function(e) {
    location.href = this.value;
  });

  $('.mobile-parent .has-children').on('click', function(e) {
    e.preventDefault();
    var url = this.getAttribute("data-href");
    if (url) {
      window.location.href = url;
    }
  });
});

window.onload = function() {
  if (isMobileDevice()) {
    toggleDisplayByClass('fb-share-button', 'none');
    toggleDisplayByClass('fb-sharer-link', 'inline-block');
  } else {
    toggleDisplayByClass('fb-sharer-link', 'none');
    toggleDisplayByClass('fb-share-button', 'inline-block');
  }
};

function preloadImage(url) {
  const link = document.createElement('link');
  link.rel = 'preload';
  link.href = url;
  link.as = 'image';
  link.setAttribute('fetchpriority', 'high');
  document.head.appendChild(link);
}

function isMobileDevice() {
  return /Mobi|Android|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function toggleDisplayByClass(className, displayStyle) {
  var elements = document.getElementsByClassName(className);
  for (var i = 0; i < elements.length; i++) {
    elements[i].style.display = displayStyle;
  }
}